import {API_URL} from "../Constants";
import instance from "./api";
class MessageService {

    leaveMessage(message)
    {
        return instance.post(`${API_URL}/api/message/`,message,{
            withCredentials:true
        })
    }
    getall()
    {
        return instance.get(`${API_URL}/api/message/all/`,{
            withCredentials:true
        })
    }
    getanswered()
    {let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.get(`${API_URL}/api/admin/message/answered/`,{
            withCredentials:true
            ,headers:{
                'Authorization':token
            }
        })
    }
    getunanswered()
    {  let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.get(`${API_URL}/api/admin/message/unanswered/`,{
            withCredentials:true
            ,headers:{
                'Authorization':token
            }
        })
    }

    updateMesForAdmin(m)
    {let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.post(`${API_URL}/api/admin/message/update/`,m,{
            withCredentials:true
            ,headers:{
                'Authorization':token
            }
        })
    }

    deleteMesForAdmin(m)
    {let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.post(`${API_URL}/api/admin/message/delete/`,m,{
            withCredentials:true
            ,headers:{
                'Authorization':token
            }
        })
    }

}
export default new MessageService()
