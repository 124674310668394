import axios from 'axios';
import {API_URL} from '../Constants'

class NewsService {

    uploadimagetominio(image)
    {
        let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return axios.post(`${API_URL}/api/admin/files/upload`, image, {
        headers: {
            "Content-type": "application/json",
            'Content-Type': 'multipart/form-data',
            'Authorization':token
        },
            withCredentials:true
    })}

    addnews(news)
    { let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return axios.post(`${API_URL}/api/admin/news/`, news,{
        headers: {
          'Authorization':token
        },
        withCredentials:true
    })
    }

    getAllNews()
    {let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return axios.get(`${API_URL}/api/news/`,{
            headers: {
                'Authorization':token
            },
            withCredentials:true
        })
    }
    updateNews(news)
    { let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return axios.post(`${API_URL}/api/news/edit`, news,{
            headers: {
                'Authorization':token
            },
            withCredentials:true
        })
    }

    deleteNews(newsid)
    { let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return axios.delete(`${API_URL}/api/news/${newsid}`,{
            headers: {
                'Authorization':token
            },
            withCredentials:true
        })
    }
}
export default new NewsService()
