import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import CKEditor from 'ckeditor4-react';

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    CardFooter
} from "reactstrap"
import {
    PostEdit,
    Title,
    Type,
    Category,
    Content,
    Post,
    Discard,
    Text,
    Audio,
    Video,
    Image,
    UsersCountryMenu2,
    Languages,
    IsActive,
    UsersTableTitle,
    UsersTableHeader,
    Edit,
    EditProfile,
    UpdateProfile,
    Delete,
    AboutUsTableHeader, MessagesTableHeader
} from "../../constant";

import {toast} from "react-toastify";
import useForceUpdate from 'use-force-update';

import Select from "react-select";

import Popup from "reactjs-popup";
import MessageService from "../../Axios/MessageService";

const ManageAnsweredMessages = () => {



    const [aboutuslang,setaboutuslang]=useState({})
    const[locale,setlocale]=useState('')
    const[content,setcontent]=useState('')
    const [aboutus,setaboutus]=useState({})
    const[aboutuses,setaboutuses]=useState([])
    const[question,setquestion]=useState('')
    const [answer,setanswer]=useState('')
    useEffect(()=>{
        MessageService.getanswered().then((res)=>{
           setaboutuses(res.data)
        })
    },[])

    const setAboutUs=(x)=>{
        setaboutus(x)
        setquestion(x.question)
        setanswer(x.answer)
    }


 const updateaboutus=(event,us)=>{
        event.preventDefault()
     aboutus.answer = answer
     aboutus.answered = true
     MessageService.updateMesForAdmin(aboutus).then((res) => {
         toast.success("Message Answered Successfully")
         setTimeout(function () {
             window.location.reload()
         }, 2000);
     }).catch((error) => {
         toast.error(error.response)
     })
 }
 const deleteaboutus=(event,a)=>{
        event.preventDefault()
        MessageService.deleteMesForAdmin(a).then((res)=>{
            toast.success("Message Successfully deleted")
            setTimeout(function () {
                window.location.reload()
            },2000)
        })
 }

    return (
        <Fragment>
            <Breadcrumb parent="Blog" title="Add Post" />
            <Container fluid={true}>
                <div className="edit-profile">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">{UsersTableTitle}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </CardHeader>
                                <div className="table-responsive">
                                    <table className="table card-table table-vcenter text-nowrap">
                                        <thead>
                                        <tr>

                                            {MessagesTableHeader.map((items,i) =>
                                                <th key={i}>{items}</th>
                                            )}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        { aboutuses.map((user,i) =>
                                            <tr key={i}>
                                                <td><a className="text-inherit" href="#javascript">{user.id} </a></td>
                                                <td>{(new Date(user.updatedAt).toLocaleDateString())}{" "}{(new Date(user.updatedAt).toLocaleTimeString())}</td>


                                                <td className="text-right">
                                                    <Popup onOpen={()=>setAboutUs(user)} trigger={ <Button id={i} color="primary" size="sm" > {Edit}</Button>} position="right center" modal nested>
                                                        <Container fluid={true}>
                                                            <Card style={{
                                                                maxHeight: 'calc(100vh - 210px)',
                                                                overflowY: 'auto'
                                                            }}>
                                                                <Col xl="8">
                                                                    <Form className="card">
                                                                        <CardHeader>
                                                                            <h4 className="card-title mb-0">{EditProfile}</h4>
                                                                            <div className="card-options">
                                                                                <a className="card-options-collapse" href="#javascript">
                                                                                    <i className="fe fe-chevron-up"></i>
                                                                                </a>
                                                                                <a className="card-options-remove" href="#javascript">
                                                                                    <i className="fe fe-x"></i>
                                                                                </a>
                                                                            </div>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <Row>
                                                                                <Col >
                                                                                    <FormGroup>
                                                                                        <Label className="form-label">Id</Label>
                                                                                        <Input className="form-control" type="text" defaultValue={user.id} />
                                                                                    </FormGroup>



                                                                                    <div className="email-wrapper">
                                                                                        <div className="theme-form">
                                                                                            <FormGroup>
                                                                                                <Label>Question</Label>
                                                                                                <CKEditor
                                                                                                    data={user.question}
                                                                                                    onChange={evt => setquestion( evt.editor.getData() )}
                                                                                                />

                                                                                            </FormGroup>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="email-wrapper">
                                                                                        <div className="theme-form">
                                                                                            <FormGroup>
                                                                                                <Label>Answer</Label>
                                                                                                <CKEditor
                                                                                                    data={user.answer}
                                                                                                    onChange={evt => setanswer( evt.editor.getData() )}
                                                                                                />

                                                                                            </FormGroup>
                                                                                        </div>
                                                                                    </div>


                                                                                </Col>
                                                                            </Row>
                                                                        </CardBody>
                                                                        <CardFooter className="text-right">
                                                                            <button className="btn btn-primary" type="submit" onClick={e=>updateaboutus(e,user)}>{UpdateProfile}</button>
                                                                        </CardFooter>
                                                                    </Form>
                                                                </Col>
                                                            </Card>
                                                        </Container>
                                                    </Popup>
                                                    {/*<Button color="transparent" size="sm" onClick={()=>updateUser1}><i className="fa fa-link"></i> {Update}</Button>*/}
                                                    <Button color="danger" size="sm" onClick={e=>deleteaboutus(e,user)}><i className="fa fa-trash"></i> {Delete}</Button>
                                                </td>
                                            </tr>
                                        )}

                                        </tbody>
                                    </table>
                                </div>
                            </Card>
                        </Col>


                    </Row>
                </div>
            </Container>

        </Fragment>
    );
}

export default ManageAnsweredMessages
