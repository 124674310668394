import axios from "axios";
import {API_URL} from "../Constants";
import {createBrowserHistory} from "history";
import AuthenticationService ,{USER_NAME_SESSION_ATTRIBUTE_NAME}from "./AuthenticationService";
const historyForce=createBrowserHistory({
    forceRefresh: true
});

const instance = axios.create({
    baseURL: `${API_URL}/api`,
    withCredentials:true
});


instance.interceptors.response.use(
    (res) => {
        return res;
    },

    async (err) => {
        const originalConfig = err.config;
        if ((originalConfig.url === `${API_URL}/api/auth/check/` && err.response)) {
            sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
            localStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
            sessionStorage.removeItem('token');
            localStorage.removeItem('token');
        }
        else
        if ((originalConfig.url === `${API_URL}/api/auth/refreshtoken/` && err.response)) {
            sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
            localStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
            sessionStorage.removeItem('token');
            localStorage.removeItem('token');
            historyForce.push("/login2")
        }
        else

       if ((originalConfig.url !== `${API_URL}/api/auth/signin/` && err.response)) {
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    await AuthenticationService.refreshAccessToken()
                    return instance(originalConfig);
                } catch (_error) {
                    historyForce.push("/login2")
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    }
);
export default instance;