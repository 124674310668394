import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button } from 'reactstrap'

import Select from 'react-select'
import {
    MyProfile,
    Bio,
    MarkJecno,
    Designer,
    Password,
    Website,
    Save,
    EditProfile,
    Company,
    Username,
    UsersCountryMenu,
    UsersCountryMenu2,
    AboutMe,
    UpdateProfile,
    UsersTableTitle,
    FirstName,
    LastName,
    Address,
    EmailAddress,
    PostalCode,
    Country,
    UsersTableHeader,
    City,
    Edit,
    Update,
    Delete,
    Languages, IsActive, DocFileTableHeader
} from '../../constant'

import {toast} from "react-toastify";
import 'reactjs-popup/dist/index.css';
import Popup from 'reactjs-popup';

import DocFileService from "../../Axios/DocFileService";
const ManageDocFile = (props) => {

    const[imageUploaded,setimageUploaded]=useState(false)
    const [name,setname]=useState('');
    const [surname,setsurname]=useState('');
    const [patronymic,setpatronymic]=useState('');
    const [content,setcontent]=useState('');
    const [imageUUID,setimageUUID]=useState('');
    const[showdropzone,setshowdropzone]=useState(true)
    const[active,setactive]=useState(false)
    const[locale,setlocale]=useState('KR')
    const[typ,settyp]=useState('TYPE_AFBITM')
    const[chorak,setchorak]=useState('03')
    const[year,setyear]=useState('')
    const[formData,setformdata]=useState(new FormData())
    const[oldid,setoldid]=useState();

    const typoptions = [

        { label: 'Affillangan Shaxslar bilan tuzilgan bitimlar', value: 'TYPE_AFBITM' },
        { label: 'Affillangan Shaxslar Ro`yxati', value: 'TYPE_AFILSH' },
        { label: 'Yirik Bitimlar', value: 'TYPE_ASBITM' },
        { label: 'Buxgalteriya Balansi', value: 'TYPE_BALANS' },
        { label: 'Baholash Natijalari', value: 'TYPE_BAXOLA' },
        { label: 'Biznes Reja', value: 'TYPE_BIZNES' },
        { label: 'Dividendlar', value: 'TYPE_DIVIDI' },
        { label: 'Muhim Faktlar', value: 'TYPE_MHFAKT' },
        { label: 'Moliyaviy Natijalar', value: 'TYPE_MOLNAT' },
        { label: 'Ustav', value: 'TYPE_USTAVI' },
        { label: 'Tashkiliy Tuzilma', value: 'TYPE_STRORG' },
        { label: 'Vakansiya', value: 'TYPE_VOKANS' },
        { label: 'Xaridlar to`g`risida ma`lumotlar', value: 'TYPE_XARIDL' },
        { label: 'Yig`ilishda qabul qilingan qarorlar', value: 'TYPE_YIGKAR' },

    ]
    const localeoptions = [
        { label: 'kirill', value: 'KR' },
        { label: 'uz', value: 'UZ' },
        { label: 'ru', value: 'RU' },
    ]

    const chorakoptions = [
        { label: 'I', value: '03' },
        { label: 'II', value: '06' },
        { label: 'III', value: '09' },
        { label: 'IV', value: '12' },
    ]

    const activeoptions = [
        { label: 'True', value: 'true' },
        { label: 'False', value: 'false' }
    ]

    const [data, setData] = useState([])
    const [users, setUsers] = useState([])
    const [hasusers, sethasusers] = useState(false)
    const [showUserEdit, setUserEdit] = useState(false)
    const[uptodate,setuptodate]=useState()
    const[docFileLocaltion,setdocFileLocaltion]=useState()

    const[lang,setlang]=useState('')
    const[id,setid]=useState('')
    const[position,setposition]=useState('')

    const [ord,setord]=useState('');

    const[u,setuser]=useState()
    const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }


    const handleChangeStatus = ({ meta, file }, status) => {  }
  const updateUser=(event,u)=>{
      event.preventDefault()
      let readyPrincipal={
          "id":{
              "year":year,
              "chorak":chorak,
              "typ":typ,
              "locale":locale
          },
          'name':name,
          'active':active,
          'uptodate':uptodate,
          'docFileLocation':docFileLocaltion

      }
    DocFileService.update(readyPrincipal,oldid.year,oldid.chorak,oldid.locale,oldid.typ).then(
        (response)=>{
          toast.success("Document has been successfully updated")
          setTimeout(function(){window.location.reload()}, 2000);
        }
    ).catch(
        function (error) {
            toast.error(error)
        }
    )

  }

  const deleteUser1=(event,x)=>{

        DocFileService.delete(x).then((res)=>{
            toast.success("Document has been deleted successfully")
            setTimeout(function(){window.location.reload()}, 2000);

        })
        .catch((error)=>toast.error(error.response.data))

  };


  useEffect(() => {
     DocFileService.getAll().then((response)=>{
          setUsers(response.data)
         sethasusers(true)

      })
  },[])

    const setUser=(u)=>{
      setuser(u)
        setyear(u.id.year)
        setoldid(u.id)
        setname(u.name)
        setactive(u.active)
        setuptodate(u.uptodate)
        setdocFileLocaltion(u.docFileLocation)
        setuptodate(u.uptodate)
    }

   const  handleChangelocale=(value)=> {

        setlocale(value.value)

    }
    const  handleChangeactive=(value)=> {

        setactive(value.value)

    }
    const setName=(event)=>{
        setname(event.target.value)
    }
    const setPosition=(event)=>{
        setposition(event.target.value)
    }
    const setSurname=(event)=>{
        setsurname(event.target.value)
    }
    const setPatronymic=(event)=>{
        setpatronymic(event.target.value)
    }

    const setActive=(event)=>{
        setactive(event.target.value)
    }

    const setLocale=(event)=>{
        setlocale(event.target.value)
    }
    const setOrd=(event)=>{
        setord(event.target.value)
    }


    return (
    <Fragment>
      <Breadcrumb parent="Users" title="Edit Profile" />
      <Container fluid={true}>
        <div className="edit-profile">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{UsersTableTitle}</h4>
                  <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                </CardHeader>
                <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                    <tr>
                      {DocFileTableHeader.map((items,i) =>
                          <th key={i}>{items}</th>
                      )}
                    </tr>
                    </thead>
                    <tbody>
                    {hasusers&&users.map((user,i) =>
                        <tr key={i}>
                          <td><a className="text-inherit" href="#javascript">{user.id.year} </a></td>
                          <td>{user.id.chorak}</td>
                          <td>{user.id.locale}</td>
                          <td>{user.id.typ}</td>
                          <td>{user.name}</td>
                          <td>{user.active.toString()}</td>
                          <td>{user.uptodate.toString()}</td>

                            <td>{user.docFileLocation.size}</td>
                          <td className="text-right">
                            <Popup onOpen={()=>setUser(user)} trigger={ <Button id={i} color="primary" size="sm" > {Edit}</Button>} position="right center" modal nested>
                              <Container fluid={true}>
                                <Card style={{
                                  maxHeight: 'calc(100vh - 210px)',
                                  overflowY: 'auto'
                                }}>
                              <Col xl="8">
                                <Form className="card">
                                  <CardHeader>
                                    <h4 className="card-title mb-0">{EditProfile}</h4>
                                    <div className="card-options">
                                      <a className="card-options-collapse" href="#javascript">
                                        <i className="fe fe-chevron-up"></i>
                                      </a>
                                      <a className="card-options-remove" href="#javascript">
                                        <i className="fe fe-x"></i>
                                      </a>
                                    </div>
                                  </CardHeader>
                                  <CardBody>
                                    <Row>
                                      <Col >
                                          <FormGroup>
                                              <Label className="form-label">Doc Type</Label>

                                              <Select
                                                  options={typoptions}
                                                  onChange={value => settyp(value.value)}
                                                  defaultValue={{ label: user.id.typ, value: user.id.typ }}
                                              />
                                          </FormGroup>

                                          <FormGroup>
                                              <Label className="form-label">Language</Label>

                                              <Select
                                                  options={localeoptions}
                                                  onChange={value => handleChangelocale(value)}
                                                  defaultValue={{ label: user.id.locale, value: user.id.locale }}
                                              />
                                          </FormGroup>
                                          <FormGroup>
                                              <Label className="form-label">Is Active</Label>
                                              <Select
                                                  options={activeoptions}
                                                  onChange={value => handleChangeactive(value)}
                                                  defaultValue={{ label:user.active.toString(), value: user.active.toString() }}
                                              />
                                          </FormGroup>
                                          <FormGroup>
                                              <Label className="form-label">Is UpToDate</Label>
                                              <Select
                                                  options={activeoptions}
                                                  onChange={value => setuptodate(value.value)}
                                                  defaultValue={{ label:user.uptodate.toString(), value: user.uptodate.toString() }}
                                              />
                                          </FormGroup>
                                          <FormGroup>
                                              <Label for="validationCustom01">Name</Label>
                                              <Input className="form-control" id="validationCustom01" type="text" defaultValue={user.name}  onChange={setName} />

                                              <Label for="validationCustom01">Year</Label>
                                              <Input className="form-control" id="validationCustom02" type="text"  defaultValue={user.id.year} onChange={(event)=>setyear(event.target.value)}/>

                                              <FormGroup>
                                                  <Label className="form-label">Chorak</Label>
                                                  <Select
                                                      options={chorakoptions}
                                                      onChange={value => setchorak(value.value)}
                                                      defaultValue={{ label:user.id.chorak, value: user.id.chorak }}
                                                  />
                                              </FormGroup>
                                          </FormGroup>


                                      </Col>
                                    </Row>
                                  </CardBody>
                                  <CardFooter className="text-right">
                                    <button className="btn btn-primary" type="submit" onClick={e=>updateUser(e,user)}>{UpdateProfile}</button>
                                  </CardFooter>
                                </Form>
                              </Col>
                                </Card>
                              </Container>
                            </Popup>
                            {/*<Button color="transparent" size="sm" onClick={()=>updateUser1}><i className="fa fa-link"></i> {Update}</Button>*/}
                            <Button color="danger" size="sm" onClick={e=>deleteUser1(e,user)}><i className="fa fa-trash"></i> {Delete}</Button>
                          </td>
                        </tr>
                    )}

                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>


          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default ManageDocFile;
