
import {API_URL} from "../Constants";
import instance from "./api";

instance.defaults.withCredentials = true
class DocFileService {


    getAll()
    {let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.get(`${API_URL}/api/admin/docfile/`,{
            headers: {
                'Authorization':token
            },
            withCredentials:true
        })
    }

    add(data)
    {let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.post(`${API_URL}/api/admin/docfile/`, data,{
            headers: {
                'Authorization':token
            },
            withCredentials:true
        })}

    update(object,year,chorak,locale,typ)
    {let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.post(`${API_URL}/api/admin/docfile/update?year=${year}&chorak=${chorak}&locale=${locale}&typ=${typ}`, object,{
            headers: {
                'Authorization':token
            },
            withCredentials:true
        })
    }
    delete(object)
    {let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.post(`${API_URL}/api/admin/docfile/delete/`, object,{
            headers: {
                'Authorization':token
            },
            withCredentials:true
        })
    }


}
export default new DocFileService()
