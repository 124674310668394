

import React,{useState,useEffect, Fragment} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import CKEditor from "react-ckeditor-component";
import {Container,Row,Col,Card,CardBody,Media,Label,Input,Button,Form,FormGroup} from 'reactstrap'
import {useDispatch,useSelector} from 'react-redux'






const NewsEdit= (props) => {
    const dispatch = useDispatch();
    const usersList = useSelector(content => content.EmailApp.allEmails);
    const TypesOfData = useSelector(mailTypes => mailTypes.EmailApp.types);
    const [compose, setCompose] = useState(true);
    const [dropdownOpen, setOpen] = useState(false);
    const [type, setType] = useState('Inbox');
    const [emailIds, setEmailIds] = useState([]);
    // eslint-disable-next-line
    const [mailData, setMailData] = useState([]);
    const [checked, setchecked] = useState(false);
    const [selectedFav, setSelectedFav] = useState(false);
    const [singleMailRecord, setSingleMailRecord] = useState({});

    const toggle = () => setOpen(!dropdownOpen);


    return (
        <Fragment>
            <Breadcrumb parent="Apps" title="Add News"/>
            <Container fluid={true}>
                <div className="email-wrap">
                    <Row>
        <Col xl="6" md="12 box-col-12">
            <div className="email-right-aside">
                <Card className="email-body radius-left">
                    <div className="pl-0">
                        <div className="tab-content">
                            <div className={`tab-pane fade ${compose ? 'active show' : ''}`} id="pills-darkhome" role="tabpanel" aria-labelledby="pills-darkhome-tab">
                                <div className="email-compose">
                                    <div className="email-top compose-border">
                                        <Row>
                                            <Col sm="8 xl-50">
                                                <h4 className="mb-0">Add News</h4>
                                            </Col>
                                            <Col sm="4 xl-50" className="btn-middle">
                                                <Button color="primary" className="btn-block btn-mail text-center mb-0 mt-0">
                                                    <i className="fa fa-paper-plane mr-2"></i>Add
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="email-wrapper">
                                        <Form className="theme-form">
                                            <FormGroup>
                                                <Label className="col-form-Label pt-0">Title</Label>
                                                <Input className="form-control" type="email"/>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label >Description</Label>
                                                <Input className="form-control" type="text"/>
                                            </FormGroup>
                                            <FormGroup className="mb-0">
                                                <Label className="text-muted">Content</Label>
                                                <CKEditor
                                                    activeclassName="p10"
                                                />
                                            </FormGroup>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane fade ${compose !== true ? 'active show' : ''}`}>
                                <div className="email-content">
                                    <div className="email-top">
                                        <Row>
                                            <Col md="6 xl-100" sm="12">
                                                <Media>
                                                    <Media className="mr-3 rounded-circle img-50"  alt="" />
                                                    <Media body>
                                                        <h6>{singleMailRecord.name} <small><span className="digits">{singleMailRecord.date}</span> <span className="digits">6:00</span> AM</small></h6>
                                                        <p>{singleMailRecord.cc}</p>
                                                    </Media>
                                                </Media>
                                            </Col>
                                            <Col md="6" sm="12">
                                                <div className="float-right d-flex" >
                                                    <p className="user-emailid">{"Lormlpsa"}<span className="digits">{"23"}</span>{"@company.com"}</p>
                                                    <i className={`fa fa-star-o f-18 mt-1 ${singleMailRecord.favourite ? 'starred' : ''} `} ></i>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    )

}

export default NewsEdit



