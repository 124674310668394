import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import CKEditor from 'ckeditor4-react';

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    CardFooter
} from "reactstrap"
import {
    PostEdit,
    Title,
    Type,
    Category,
    Content,
    Post,
    Discard,
    Text,
    Audio,
    Video,
    Image,
    UsersCountryMenu2,
    Languages,
    IsActive,
    UsersTableTitle,
    UsersTableHeader,
    Edit,
    EditProfile,
    UpdateProfile,
    Delete,
    AboutUsTableHeader, MessagesTableHeader, SubscribeTableHeader
} from "../../constant";

import SubscribersService from "../../Axios/SubscribersService";

const SubscribersList = () => {

    const [aboutuslang,setaboutuslang]=useState({})
    const[locale,setlocale]=useState('')
    const[content,setcontent]=useState('')
    const [aboutus,setaboutus]=useState({})
    const[aboutuses,setaboutuses]=useState([])
    const[question,setquestion]=useState('')
    const [answer,setanswer]=useState('')
    useEffect(()=>{
        SubscribersService.getAll().then((res)=>{
           setaboutuses(res.data)
        })
    },[])


    return (
        <Fragment>
            <Breadcrumb parent="Blog" title="Add Post" />
            <Container fluid={true}>
                <div className="edit-profile">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">{UsersTableTitle}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </CardHeader>
                                <div className="table-responsive">
                                    <table className="table card-table table-vcenter text-nowrap">
                                        <thead>
                                        <tr>
                                            {SubscribeTableHeader.map((items,i) =>
                                                <th key={i}>{items}</th>
                                            )}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        { aboutuses.map((user,i) =>
                                            <tr key={i}>
                                                <td><a className="text-inherit" href="#javascript">{user.email} </a></td>
                                                <td>{(new Date(user.createdAt).toLocaleDateString())}{" "}{(new Date(user.createdAt).toLocaleTimeString())}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </Card>
                        </Col>


                    </Row>
                </div>
            </Container>

        </Fragment>
    );
}

export default SubscribersList
