import React, {Fragment, useState} from 'react';
import Breadcrumb from '../layout/breadcrumb'
import CKEditor from 'ckeditor4-react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Dropzone from 'react-dropzone-uploader'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap"
import {PostEdit,Title,Type,Category,Content,Post,Discard,Text,Audio,Video,Image} from "../constant";
import NewsService from "../Axios/NewsService";
import {toast} from "react-toastify";
import useForceUpdate from 'use-force-update';
import GalleryVideoService from "../Axios/GalleryVideoService";
import { v4 as uuidv4 } from 'uuid';
const GalleryVideoPost = () => {

  const[imageUploaded,setimageUploaded]=useState(false)

  const [title_uz,settitle_uz]=useState('');
  const [description_uz,setdescription_uz]=useState('');


  const [title_ru,settitle_ru]=useState('');
  const [description_ru,setdescription_ru]=useState('');

  const [title_kirill,settitle_kirill]=useState('');
  const [description_kirill,setdescription_kirill]=useState('');

  const [imageUUID,setimageUUID]=useState('');
  const[showdropzone,setshowdropzone]=useState(true)

  const AddNews =()=>{
 {
      let gPicture={
        'description_uz':description_uz,
        'title_uz':title_uz,
        'description_ru':description_ru,
        'title_ru':title_ru,
        'description_kirill':description_kirill,
        'title_kirill':title_kirill,
        'minioVideoId':imageUUID
      }

   GalleryVideoService.addGalleryPicture(gPicture).then((response)=>{toast.success("Muvaffaqiyatli yuklandi")})
      setTimeout(function(){window.location.reload()}, 2000);
  }}





    // called every time a file's `status` changes
  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {  }

  const fsettitle_uz=(event)=>{
    settitle_uz(event.target.value)
  }
  const fsetdescription_uz=(event)=>{
    setdescription_uz(event.target.value)
  }
  const fsettitle_kirill=(event)=>{
    settitle_kirill(event.target.value)
  }
  const fsetdescription_kirill=(event)=>{
    setdescription_kirill(event.target.value)
  }

    const fsetuuid=(event)=>{
        setimageUploaded(true)
        setshowdropzone(false)
        setimageUUID(event.target.value)
    }
  const fsettitle_ru=(event)=>{
    settitle_ru(event.target.value)
  }
  const fsetdescription_ru=(event)=>{
    setdescription_ru(event.target.value)
  }
  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    let formData=new FormData()
    formData.append("file",files[0].file)
    GalleryVideoService.uploadimagetominio(formData).then((response)=>{
      setimageUUID(response.data)
      toast.success("Video muvaffaqiyatli yuklandi")

        setimageUploaded(true)
        setshowdropzone(false)
    })
    allFiles.forEach(f => f.remove())



  }
  return (
    <Fragment>
      <Breadcrumb parent="Blog" title="Add Post" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{PostEdit}</h5>
              </CardHeader>
              <CardBody className="add-post">
                <Form className="row needs-validation">
                  <Col sm="12">

                    <FormGroup>
                      <Label for="validationCustom01">Title_uz:</Label>
                      <Input className="form-control" id="validationCustom01" type="text" placeholder="Post Title" required=""onChange={fsettitle_uz} name={title_uz} />
                      <Label for="validationCustom01">Description_UZ:</Label>
                      <Input className="form-control" id="validationCustom01" type="text" placeholder="Post Title" required=""onChange={fsetdescription_uz} name={description_uz} />
                      <Label for="validationCustom01">Title_ru:</Label>
                      <Input className="form-control" id="validationCustom01" type="text" placeholder="Post Title" required=""onChange={fsettitle_ru} name={title_ru} />
                      <Label for="validationCustom01">Description_ru:</Label>
                      <Input className="form-control" id="validationCustom01" type="text" placeholder="Post Title" required=""onChange={fsetdescription_ru} name={description_ru} />
                      <Label for="validationCustom01">Title_kirill:</Label>
                      <Input className="form-control" id="validationCustom01" type="text" placeholder="Post Title" required=""onChange={fsettitle_kirill} name={title_kirill} />
                      <Label for="validationCustom01">Description_kirill:</Label>
                      <Input className="form-control" id="validationCustom01" type="text" placeholder="Post Title" required=""onChange={fsetdescription_kirill} name={description_kirill} />
                        <Label for="validationCustom01">UUID VIDEO:</Label>
                        <Input className="form-control" id="validationCustom09" type="text" placeholder="Post Title" required=""onChange={fsetuuid}  />

                      <div className="valid-feedback">{"Looks good!"}</div>

                    </FormGroup>
                    {/*<FormGroup>*/}
                    {/*  <Label>{Type}:</Label>*/}
                    {/*  /!*<div className="m-checkbox-inline">*!/*/}
                    {/*  /!*  <Label for="edo-ani">*!/*/}
                    {/*  /!*    <Input className="radio_animated" id="edo-ani" type="radio" name="rdo-ani" />{Text}*!/*/}
                    {/*  /!*      </Label>*!/*/}
                    {/*  /!*  <Label for="edo-ani1">*!/*/}
                    {/*  /!*    <Input className="radio_animated" id="edo-ani1" type="radio" name="rdo-ani" />{Image}*!/*/}
                    {/*  /!*      </Label>*!/*/}
                    {/*  /!*  <Label for="edo-ani2">*!/*/}
                    {/*  /!*    <Input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" defaultChecked />{Audio}*!/*/}
                    {/*  /!*      </Label>*!/*/}
                    {/*  /!*  <Label for="edo-ani3">*!/*/}
                    {/*  /!*    <Input className="radio_animated" id="edo-ani3" type="radio" name="rdo-ani" />{Video}*!/*/}
                    {/*  /!*      </Label>*!/*/}
                    {/*  /!*</div>*!/*/}
                    {/*</FormGroup>*/}
                    {/*<FormGroup>*/}
                    {/*  <div className="col-form-Label">{Category}:*/}
                    {/*        <Typeahead*/}
                    {/*          id="multiple-typeahead"*/}
                    {/*          className="mt-2"*/}
                    {/*          clearButton*/}
                    {/*          defaultSelected={data.slice(0, 5)}*/}
                    {/*          labelKey="name"*/}
                    {/*          multiple*/}
                    {/*          options={data}*/}
                    {/*          placeholder="Select Your Name...."*/}
                    {/*        />*/}
                    {/*  </div>*/}
                    {/*</FormGroup>*/}

                  </Col>
                </Form>
                {!imageUploaded&&<Form className="m-b-20">
                  <div className="m-0 dz-message needsclick">
                    <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmit}
                        accept="video/*"
                        canCancel={true}
                        multiple={false}
                        maxFiles={1}
                    />
                    {/*<Dropzone*/}
                    {/*    getUploadParams={getUploadParams}*/}
                    {/*    onChangeStatus={handleChangeStatus}*/}
                    {/*    onSubmit={handleSubmit}*/}
                    {/*    accept="image/*"*/}

                    {/*  maxFiles={1}*/}
                    {/*  multiple={false}*/}
                    {/*  canCancel={false}*/}
                    {/*  inputContent="Drop files here or click to upload."*/}
                    {/*  styles={{*/}
                    {/*    dropzone: { width: '100%', height: 50 },*/}
                    {/*    dropzoneActive: { borderColor: 'green' },*/}
                    {/*  }}*/}
                    {/*/>*/}
                  </div>
                </Form>}
                <div className="btn-showcase">
                  {imageUploaded&&<Button color="primary" type="submit" onClick={AddNews}>{Post}</Button>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default GalleryVideoPost
