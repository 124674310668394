import React, {Fragment, useState} from 'react';
import Breadcrumb from '../../layout/breadcrumb'

import Dropzone from 'react-dropzone-uploader'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap"
import {
  PostEdit,

  Post,

} from "../../constant";

import {toast} from "react-toastify";

import Select from "react-select";
import DocFileService from "../../Axios/DocFileService";
const AddReport = () => {

  const[imageUploaded,setimageUploaded]=useState(false)
  const [name,setname]=useState('');
  const [surname,setsurname]=useState('');
  const [patronymic,setpatronymic]=useState('');
  const [content,setcontent]=useState('');
  const [imageUUID,setimageUUID]=useState('');
  const[showdropzone,setshowdropzone]=useState(true)
  const[active,setactive]=useState(false)
  const[locale,setlocale]=useState('KR')
  const[typ,settyp]=useState('TYPE_AFBITM')
  const[chorak,setchorak]=useState('03')
  const[year,setyear]=useState('')
 const[formData,setformdata]=useState(new FormData())
    const[uptodate,setuptodate]=useState(false)
  const typoptions = [
    { label: 'Affillangan Shaxslar bilan tuzilgan bitimlar', value: 'TYPE_AFBITM' },
    { label: 'Affillangan Shaxslar Ro`yxati', value: 'TYPE_AFILSH' },
    { label: 'Yirik Bitimlar', value: 'TYPE_ASBITM' },
    { label: 'Buxgalteriya Balansi', value: 'TYPE_BALANS' },
    { label: 'Baholash Natijalari', value: 'TYPE_BAXOLA' },
    { label: 'Biznes Reja', value: 'TYPE_BIZNES' },
    { label: 'Dividendlar', value: 'TYPE_DIVIDI' },
    { label: 'Muhim Faktlar', value: 'TYPE_MHFAKT' },
    { label: 'Moliyaviy Natijalar', value: 'TYPE_MOLNAT' },
    { label: 'Ustav', value: 'TYPE_USTAVI' },
    { label: 'Tashkiliy Tuzilma', value: 'TYPE_STRORG' },
    { label: 'Vakansiya', value: 'TYPE_VOKANS' },
    { label: 'Xaridlar to`g`risida ma`lumotlar', value: 'TYPE_XARIDL' },
    { label: 'Yig`ilishda qabul qilingan qarorlar', value: 'TYPE_YIGKAR' },
      { label: 'Корхона томонидан бажариладиган ишлар', value: 'TYPE_RABOTI' },
      { label: 'Bush ish orinlari', value: 'TYPE_VOKANS' },

      { label: 'Asosiy yunalish va maqsad', value: 'TYPE_NAPZAD' },

  ]
  let readyPrincipal={
    "id":{
      "year":year,
      "chorak":chorak,
      "typ":typ,
      "locale":locale
    },
    'name':name,
    'active':active,
    'uptodate':uptodate

  }
  const addPrincipal =()=>{


    formData.append('docfile', new Blob([JSON.stringify(readyPrincipal)], {
      type: "application/json"
    }));

    DocFileService.add(formData).then((res)=>{
      toast.success("Document has been added successfully")
      setTimeout(function(){window.location.reload()}, 2000);
    })

      }
  const onChangeInEditor = (event, editor) => {
    const data = editor.getData()

    setcontent(data)

  }
  const updateContent=(newContent)=> {
    setcontent(newContent)
  }

  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }


  const handleChangeStatus = ({ meta, file }, status) => {  }


  const setName=(event)=>{
    setname(event.target.value)
  }

  const setSurname=(event)=>{
    setsurname(event.target.value)
  }
  const setPatronymic=(event)=>{
    setpatronymic(event.target.value)
  }

  const setActive=(event)=>{
    setactive(event.target.value)
  }


  const handleSubmit = (files, allFiles) => {
    formData.append("file",files[0].file)
    allFiles.forEach(f => f.remove())
    setimageUploaded(true)
    setshowdropzone(false)
  }
  const localeoptions = [
    { label: 'kirill', value: 'KR' },
    { label: 'uz', value: 'UZ' },
    { label: 'ru', value: 'RU' },
  ]

  const chorakoptions = [
    { label: 'I', value: '03' },
    { label: 'II', value: '06' },
    { label: 'III', value: '09' },
    { label: 'IV', value: '12' },
  ]

  const activeoptions = [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' }
  ]
  const  handleChangelocale=(value)=> {

    setlocale(value.value)

  }
  const  handleChangeactive=(value)=> {

    setactive(value.value)

  }
  return (
    <Fragment>
      <Breadcrumb parent="Blog" title="Add Post" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{PostEdit}</h5>
              </CardHeader>
              <CardBody className="add-post">
                <Form className="row needs-validation">
                  <Col sm="12">

                    <FormGroup>
                      <Label className="form-label">Doc Type</Label>

                      <Select
                          options={typoptions}
                          onChange={value => settyp(value.value)}
                          defaultValue={{ label: "Affillangan Shaxslar bilan tuzilgan bitimlar", value: "TYPE_AFBITM" }}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label className="form-label">Language</Label>

                      <Select
                          options={localeoptions}
                          onChange={value => handleChangelocale(value)}
                          defaultValue={{ label: "Kirill", value: "KR" }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-label">Is Active</Label>
                      <Select
                          options={activeoptions}
                          onChange={value => handleChangeactive(value)}
                          defaultValue={{ label:"False", value: false }}
                      />
                    </FormGroup>
                      <FormGroup>
                          <Label className="form-label">Is UpToDate</Label>
                          <Select
                              options={activeoptions}
                              onChange={value => setuptodate(value.value)}
                              defaultValue={{ label:"False", value: false }}
                          />
                      </FormGroup>
                    <FormGroup>
                      <Label for="validationCustom01">Name</Label>
                      <Input className="form-control" id="validationCustom01" type="text"  onChange={setName} />

                      <Label for="validationCustom01">Year</Label>
                      <Input className="form-control" id="validationCustom02" type="text"  onChange={(event)=>setyear(event.target.value)}/>

                      <FormGroup>
                        <Label className="form-label">Chorak</Label>
                        <Select
                            options={chorakoptions}
                            onChange={value => setchorak(value.value)}
                            defaultValue={{ label:"I", value: "03" }}
                        />
                      </FormGroup>
                    </FormGroup>



                  </Col>
                </Form>
                <Form className="m-b-20">
                  <div className="m-0 dz-message needsclick">

                    <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmit}
                        accept="application/pdf"
                        maxFiles={1}
                        canCancel={false}
                        multiple={false}

                    />

                  </div>
                </Form>
                <div className="btn-showcase">

                  {imageUploaded&&<Button color="primary" type="submit" onClick={addPrincipal}>{Post}</Button>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default AddReport
