
import {API_URL} from "../Constants";
import instance from "./api";

instance.defaults.withCredentials = true
class SubscribersService {
    getAll()
    {let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')

        return instance.get(`${API_URL}/api/admin/subscribe/`,{
            withCredentials:true
            ,headers:{
                'Authorization':token
            }
        })
    }

    sendMessageToAllSubscribers(request)
    {let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.post(`${API_URL}/api/admin/subscribe/toall/`,request,{
            withCredentials:true
            ,headers:{
                'Authorization':token
            }
        })
    }
}
export default new SubscribersService()
