import React, {Component, Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import blog from "../../assets/images/blog/blog.jpg";
import blog2 from "../../assets/images/blog/blog-2.jpg";
import blog3 from "../../assets/images/blog/blog-3.jpg";
import blog5 from "../../assets/images/blog/blog-5.jpg";
import blog6 from "../../assets/images/blog/blog-6.jpg";
import 'reactjs-popup/dist/index.css';
import Popup from 'reactjs-popup';
import Dropzone from 'react-dropzone-uploader';
import CKEditor from 'ckeditor4-react';



import {
    Container,
    Row,
    Col,
    Card,
    Media,
    CardHeader,
    Button,
    Form,
    CardBody,
    FormGroup,
    Label,
    Input,
    CardFooter
} from "reactstrap"
import {
    Delete,
    Edit,
    EditProfile,
    MarkJecno,
    NewsTableHeader, Post, PostEdit,
    UpdateProfile,
    UsersCountryMenu,
    UsersTableTitle
} from "../../constant";
import UsersService from "../../Axios/UsersService";
import NewsService from "../../Axios/NewsService";
import axios from "axios";
import {toast} from "react-toastify";
import {win} from "leaflet/src/core/Browser";



export class BlogDetail extends Component  {

    constructor() {
        super();
        this.state={
            news:[],
            thenews:[],

        }
    }



     getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }
     updateNews =()=>{

              NewsService.updateNews(this.state.thenews).then((response)=>{toast.success("News Updated")})
                  .catch((reason => {toast.error("Something went wrong")}))

    }
    // called every time a file's `status` changes
    handleChangeStatus = ({ meta, file }, status) => {  }
     editNews =(x)=>{




    };

    componentDidMount() {
        NewsService.getAllNews().then((response)=>this.setState({
            news:response.data
        }))
    }


    deleteNews=(x)=>{
        NewsService.deleteNews(x.id).then((response)=>{toast.success("The News has been deleted")
                setTimeout(function(){window.location.reload()}, 2000);

        }

        ).catch(()=>{
            toast.error("Something went wrong")

        })


    };


     fsettile_uz=(event)=>{
        this.state.thenews.title_uz=event.target.value
    }
     fsetdescription_uz=(event)=>{
         this.state.thenews.desription_uz=event.target.value
    }
     fsettile_ru=(event)=>{
         this.state.thenews.title_ru=event.target.value
    }
     fsetdescription_ru=(event)=>{
         this.state.thenews.description_ru=event.target.value
    }
    // receives array of files that are done uploading when submit button is clicked
     handleSubmit = (files, allFiles) => {
        let formData=new FormData()
        formData.append("file",files[0].file)

        NewsService.uploadimagetominio(formData).then((response)=>{

            this.state.thenews.image.id=response.data
            toast.success("Image has been successfully uploaded")

        })
        allFiles.forEach(f => f.remove())

    }
    render(){
    return (
        <Fragment>

            <Breadcrumb parent="Blog" title="Blog Details" />
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <h4 className="card-title mb-0">{UsersTableTitle}</h4>
                                <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                            </CardHeader>
                            <div className="table-responsive">
                                <table className="table card-table table-vcenter text-nowrap">
                                    <thead>
                                    <tr>
                                        {NewsTableHeader.map((items,i) =>
                                            <th key={i}>{items}</th>
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {this.state.news.map((news,i) =>

                                        <tr key={i}>




                                            <td><a className="text-inherit" href="#javascript">{news.id} </a></td>
                                            <td>{news.title_uz}</td>
                                            <td><span className="status-icon bg-success"></span>{news.title_ru}</td>
                                            <td>{news.description_uz}</td>
                                            <td>{news.description_ru}</td>
                                            <td>{news.createdAt}</td>
                                            <td className="text-right">
                                                <Popup onOpen={()=>this.setState({thenews:news})} trigger={<Button  id={i} color="primary" size="sm"> {Edit}</Button>} position="right center" modal nested>


                                                    <Container fluid={true}>
                                                            <Card style={{
                                                                maxHeight: 'calc(100vh - 210px)',
                                                                overflowY: 'auto'
                                                            }}>

                                                                <CardHeader>
                                                                    <h5>{PostEdit}</h5>
                                                                </CardHeader>

                                                                <CardBody className="add-post" >
                                                                    <Form className="row needs-validation">
                                                                        <Col sm="12">
                                                                            <FormGroup>
                                                                                <Label for="validationCustom01">Title_UZ:</Label>
                                                                                <Input className="form-control" id="validationCustom01" type="text" placeholder="Post Title" required="" onChange={this.fsettile_uz} defaultValue={news.title_uz}/>
                                                                                <div className="valid-feedback">{"Looks good!"}</div>
                                                                                <Label for="validationCustom01">Title_RU:</Label>
                                                                                <Input className="form-control" id="validationCustom01" type="text" placeholder="Post Title" required="" onChange={this.fsettile_ru} defaultValue={news.title_ru}/>
                                                                                <div className="valid-feedback">{"Looks good!"}</div>
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Label for="validationCustom01">Description_UZ:</Label>
                                                                                <Input className="form-control" id="validationCustom01" type="text" placeholder="Post Title" required=""onChange={this.fsetdescription_uz} defaultValue={news.description_uz} />
                                                                                <div className="valid-feedback">{"Looks good!"}</div>
                                                                                <Label for="validationCustom01">Description_RU:</Label>
                                                                                <Input className="form-control" id="validationCustom01" type="text" placeholder="Post Title" required=""onChange={this.fsetdescription_ru} defaultValue={news.description_ru} />
                                                                                <div className="valid-feedback">{"Looks good!"}</div>
                                                                            </FormGroup>
                                                                            {/*<FormGroup>*/}
                                                                            {/*  <Label>{Type}:</Label>*/}
                                                                            {/*  /!*<div className="m-checkbox-inline">*!/*/}
                                                                            {/*  /!*  <Label for="edo-ani">*!/*/}
                                                                            {/*  /!*    <Input className="radio_animated" id="edo-ani" type="radio" name="rdo-ani" />{Text}*!/*/}
                                                                            {/*  /!*      </Label>*!/*/}
                                                                            {/*  /!*  <Label for="edo-ani1">*!/*/}
                                                                            {/*  /!*    <Input className="radio_animated" id="edo-ani1" type="radio" name="rdo-ani" />{Image}*!/*/}
                                                                            {/*  /!*      </Label>*!/*/}
                                                                            {/*  /!*  <Label for="edo-ani2">*!/*/}
                                                                            {/*  /!*    <Input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" defaultChecked />{Audio}*!/*/}
                                                                            {/*  /!*      </Label>*!/*/}
                                                                            {/*  /!*  <Label for="edo-ani3">*!/*/}
                                                                            {/*  /!*    <Input className="radio_animated" id="edo-ani3" type="radio" name="rdo-ani" />{Video}*!/*/}
                                                                            {/*  /!*      </Label>*!/*/}
                                                                            {/*  /!*</div>*!/*/}
                                                                            {/*</FormGroup>*/}
                                                                            {/*<FormGroup>*/}
                                                                            {/*  <div className="col-form-Label">{Category}:*/}
                                                                            {/*        <Typeahead*/}
                                                                            {/*          id="multiple-typeahead"*/}
                                                                            {/*          className="mt-2"*/}
                                                                            {/*          clearButton*/}
                                                                            {/*          defaultSelected={data.slice(0, 5)}*/}
                                                                            {/*          labelKey="name"*/}
                                                                            {/*          multiple*/}
                                                                            {/*          options={data}*/}
                                                                            {/*          placeholder="Select Your Name...."*/}
                                                                            {/*        />*/}
                                                                            {/*  </div>*/}
                                                                            {/*</FormGroup>*/}
                                                                            <div className="email-wrapper">
                                                                                <div className="theme-form">
                                                                                    <FormGroup>
                                                                                        <Label>Content_UZ:</Label>
                                                                                        <CKEditor
                                                                                            data={news.content_uz}
                                                                                            onChange={evt => this.state.thenews.content_uz=evt.editor.getData()}

                                                                                        />
                                                                                        <Label>Content_RU:</Label>
                                                                                        <CKEditor
                                                                                            data={news.content_ru}
                                                                                            onChange={evt => this.state.thenews.content_ru=evt.editor.getData()}

                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Form>
                                                                    <Form className="m-b-20">
                                                                        <div className="m-0 dz-message needsclick">
                                                                            <Dropzone
                                                                                getUploadParams={this.getUploadParams}
                                                                                onChangeStatus={this.handleChangeStatus}
                                                                                onSubmit={this.handleSubmit}
                                                                                accept="image/*"
                                                                                maxFiles={1}
                                                                                canCancel={false}
                                                                                multiple={false}

                                                                            />
                                                                            {/*<Dropzone*/}
                                                                            {/*    getUploadParams={getUploadParams}*/}
                                                                            {/*    onChangeStatus={handleChangeStatus}*/}
                                                                            {/*    onSubmit={handleSubmit}*/}
                                                                            {/*    accept="image/*"*/}

                                                                            {/*  maxFiles={1}*/}
                                                                            {/*  multiple={false}*/}
                                                                            {/*  canCancel={false}*/}
                                                                            {/*  inputContent="Drop files here or click to upload."*/}
                                                                            {/*  styles={{*/}
                                                                            {/*    dropzone: { width: '100%', height: 50 },*/}
                                                                            {/*    dropzoneActive: { borderColor: 'green' },*/}
                                                                            {/*  }}*/}
                                                                            {/*/>*/}
                                                                        </div>
                                                                    </Form>
                                                                    <div className="btn-showcase">
                                                                        <Button color="primary" type="submit" onClick={this.updateNews}>{Post}</Button>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                    </Container>

                                                </Popup>

                                                {/*<Button color="transparent" size="sm" onClick={()=>updateUser1}><i className="fa fa-link"></i> {Update}</Button>*/}
                                                <Button color="danger" size="sm" onClick={()=>this.deleteNews(news)}><i className="fa fa-trash"></i> {Delete}</Button>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>




                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );}
};

export default BlogDetail;
