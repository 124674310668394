import React,{useState,useEffect} from 'react';
import man from '../../assets/images/dashboard/profile.jpg';
import {Container,Row,Col,Form,FormGroup,Input,Label,Button,NavItem, NavLink, Nav,TabContent,TabPane} from 'reactstrap'
import {firebase_app,googleProvider,facebookProvider,githubProvider, Jwt_token } from '../../data/config'
import { handleResponse } from '../../services/fack.backend'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify';
import {withRouter} from 'react-router-dom'
import { Facebook, GitHub} from 'react-feather'
import {Password,SignIn, EmailAddress,RememberPassword,ForgotPassword ,CreateAccount,FIREBASE,AUTH0,JWT,LoginWithJWT } from '../../constant';
import AuthenticationService from "../../Axios/AuthenticationService";
import history from "../../index";
import { createBrowserHistory } from 'history';
const historyForce=createBrowserHistory({
    forceRefresh: true
});

const Logins = (props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading,setLoading] = useState(false);
    const [selected, setSelected] = useState("jwt");
    const [togglePassword,setTogglePassword] = useState(false)
    useEffect(()=>{
        AuthenticationService.check222().then((res)=>{
            sessionStorage.setItem('loggedin','true')
            historyForce.push("/")
        }).catch((err)=>{
            historyForce.push("/login2")
        })
    },[])
    const loginWithJwt = (email,password) =>
    {
        let user={
            username:email,
            password:password
        }
         AuthenticationService.signin(user).then(()=>{
             sessionStorage.setItem('loggedin','true')
             history.push("/")
        })
    }

    return (
        <Container fluid={true} className="p-0">
        <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div className="login-main login-tab"> 
                <Nav className="border-tab flex-column" tabs>
                  <NavItem>
                    <NavLink className={selected === 'jwt' ? 'active' : ''} onClick={() => setSelected('jwt')}>
                    <img src={require("../../assets/images/jwt.svg")} alt="" />
                    <span>{JWT}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={selected} className="content-login">
                  <TabPane  className="fade show" tabId={selected === "firebase" ? "firebase" : "jwt"}>
                    <Form className="theme-form">
                      <h4>{selected === "firebase" ? "Sign In With Firebase" : "Admin Panel"}</h4>
                      <p>{"Enter your email & password to login"}</p>
                      <FormGroup>
                        <Label className="col-form-label">{EmailAddress}</Label>
                        <Input className="form-control" type="email" required="" onChange={e => setEmail(e.target.value)} defaultValue={email} />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">{Password}</Label>
                        <Input className="form-control" type={togglePassword ?  "text" : "password"} onChange={e => setPassword(e.target.value)} defaultValue={password} required=""/>
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                      </FormGroup>
                      <div className="form-group mb-0">
                        <Button color="primary" className="btn-block" onClick={() => loginWithJwt(email,password)}>{LoginWithJWT}</Button>
                      </div>
                    </Form>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
        </Row>
        </Container>
    );
}

export default withRouter(Logins);