import axios from 'axios';
import {API_URL} from '../Constants'


class GalleryVideoService {

    uploadimagetominio(image)
    {
        let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return axios.post(`${API_URL}/api/files/upload`, image
        ,{
            withCredentials:true
            ,headers:{
                'Content-Type': 'multipart/form-data',
                'Authorization':token
            }
        })}

    addGalleryPicture(gPicture)
    {let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return axios.post(`${API_URL}/api/gallery/videos`, gPicture,{
            withCredentials:true
            ,headers:{
                'Authorization':token
            }
        })
    }

    getAllVideos()
    {let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return axios.get(`${API_URL}/api/gallery/videos`,{
            withCredentials:true
            ,headers:{
                'Authorization':token
            }
        })
    }

}
export default new GalleryVideoService()
