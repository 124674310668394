import React, {Fragment, useState} from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import CKEditor from 'ckeditor4-react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Dropzone from 'react-dropzone-uploader'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap"
import {
  PostEdit,
  Title,
  Type,
  Category,
  Content,
  Post,
  Discard,
  Text,
  Audio,
  Video,
  Image,
  UsersCountryMenu2, Languages, IsActive
} from "../../constant";

import {toast} from "react-toastify";
import SubscribersService from "../../Axios/SubscribersService";


const SendMessageToAllSubscribers = () => {
  const Loader = () => (
      <div class="divLoader" style={{display: "flex",justifyContent: "center",alignItems:"center",position:'fixed',
        padding:0,
        margin:0,
        background:"white",
        top:0,
        left:0,
        zIndex:10000000001,
        width: '100%',
        height: '100%'}}>
        <svg class="svgLoader" viewBox="0 0 100 100" width="12em" height="12em">
          <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path>
        </svg>
      </div>
  );
  const [title,settitle]=useState('');
  const[loadingscreen,setloadingscreen]=useState(false)
  const[url,seturl]=useState('')
  const addPrincipal =()=>{

    let request={
      'title':title,
      'url':url
    }
    setloadingscreen(true)
    SubscribersService.sendMessageToAllSubscribers(request).then((response)=>{
      setloadingscreen(false)
      toast.success("Message Send To All Subscribers successfully")
      setTimeout(function(){window.location.reload()}, 2000);})

  }

  return (
    <Fragment>
      {loadingscreen&&<Loader/>}
      <Breadcrumb parent="Blog" title="Add Post" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{PostEdit}</h5>
              </CardHeader>
              <CardBody className="add-post">
                <Form className="row needs-validation">
                  <Col sm="12">

                    <FormGroup>
                      <Label for="validationCustom01">Title</Label>
                      <Input className="form-control" id="validationCustom01" type="text"  onChange={(event)=>settitle(event.target.value)} />

                   </FormGroup>

                    <FormGroup>
                      <Label for="validationCustom01">Url</Label>
                      <Input className="form-control" id="validationCustom01" type="text"  onChange={(event)=>seturl(event.target.value)} />

                    </FormGroup>



                  </Col>
                </Form>


                <div className="btn-showcase">
                  <Button color="primary" type="submit" onClick={addPrincipal}>Send to All Subscribers</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default SendMessageToAllSubscribers
