import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
import DatePicker from "react-datepicker";
import { Send, Clock } from 'react-feather';
import AuthenticationService from "../../Axios/AuthenticationService";
import {createBrowserHistory} from "history";

import MessageService from './../../Axios/MessageService'
import { Database, ShoppingBag, MessageCircle, UserPlus, Layers, ShoppingCart, DollarSign, ArrowDown, ArrowUp, CloudDrizzle } from 'react-feather';
import { smallchart1data, smallchart1option, smallchart2data, smallchart2option, smallchart3data, smallchart3option, smallchart4data, smallchart4option } from './chartsData/chartist-charts-data'
import ChartistChart from 'react-chartist';

const historyForce=createBrowserHistory({
  forceRefresh: true
});
const Default = (props) => {
  const[inter,setinter]=useState('')
  const[stat,setstat]=useState('')
  const[loca,setloca]=useState('')
  const [daytimes,setDayTimes] = useState()
  const today = new Date()
  const curHr = today.getHours()
  const curMi = today.getMinutes()
  const [meridiem,setMeridiem] = useState("AM")
  // eslint-disable-next-line
  const [date, setDate] = useState({ date: new Date() });
  // eslint-disable-next-line
  const [startDate, setStartDate] = useState(new Date());
  const[unm,setunm]=useState(0)
  const handleChange = date => {
    setDate(date)
  };

  useEffect(() => {
    if (curHr < 12) {
      setDayTimes('Good Morning')
    }else if (curHr < 18) {
      setDayTimes('Good Afternoon')
    }else {
      setDayTimes('Good Evening')
    }

    if(curHr >= 12){
     setMeridiem('PM')
    }else{
      setMeridiem('AM')
    }
  MessageService.getunanswered().then((res)=>{
    setunm(res.data.length)
  })



    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Default" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="4 xl-50" lg="12" className="morning-sec box-col-12">
            <Card className="o-hidden profile-greeting">
              <CardBody>
                <div className="media">
                  <div className="badge-groups w-100">
                    <div className="badge f-12"  style={{color:"black"}}>
                      <Clock style={{width:"16px" ,height:"16px",color:"black"}} className="mr-1"/>
                      <span id="txt" style={{color:"black"}}>{curHr}:{curMi < 10 ? "0"+curMi :curMi} {meridiem}</span>
                    </div>
                    <div className="badge f-12"  style={{color:"black"}}><i className="fa fa-spin fa-cog f-14"  style={{color:"black"}}></i></div>
                  </div>
                </div>
                <div className="greeting-user text-center" style={{color:"black"}} >

                  <h4 className="f-w-600"  style={{color:"black"}}><span id="greeting"  style={{color:"black"}}>{daytimes}</span> <span className="right-circle"  style={{color:"black"}}><i className="fa fa-check-circle f-14 middle"></i></span></h4>
                  <p style={{fontSize:"20px"}}><span> Unsanswered messages : <strong style={{color:"red"}}>{unm}</strong> </span></p>

                  <div className="left-icon"  style={{color:"black"}}><i className="fa fa-bell" style={{color:"black"}}> </i></div>
                </div>
              </CardBody>
            </Card>
          </Col>


          <Col xl="4 xl-50" lg="12" className="calendar-sec box-col-6">
            <Card className="gradient-primary o-hidden">
              <CardBody>
                <div className="default-datepicker">
                  <DatePicker
                    selected={startDate}
                    onChange={handleChange}
                    inline
                  />
                </div><span className="default-dots-stay overview-dots full-width-dots"><span className="dots-group"><span className="dots dots1"></span><span className="dots dots2 dot-small"></span><span className="dots dots3 dot-small"></span><span className="dots dots4 dot-medium"></span><span className="dots dots5 dot-small"></span><span className="dots dots6 dot-small"></span><span className="dots dots7 dot-small-semi"></span><span className="dots dots8 dot-small-semi"></span><span className="dots dots9 dot-small">                </span></span></span>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Col xl="9 xl-100" className="chart_data_left box-col-12">
          <Card>
            <CardBody className="p-0">
              <Row className="m-0 chart-main">
                <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                  <div className="media align-items-center">
                    <div className="hospital-small-chart">
                      <div className="small-bar">
                        <ChartistChart
                            className="small-chart1 flot-chart-container"
                            data={smallchart2data}
                            options={smallchart2option}
                            type={'Bar'}
                            listener={{
                              'draw': function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px'
                                  });
                                }
                              }
                            }}
                        />
                      </div>
                    </div>
                    <div className="media-body">
                      <div className="right-chart-content">
                        <h4>{inter}{' '}KM</h4><span>International </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                  <div className="media align-items-center">
                    <div className="hospital-small-chart">
                      <div className="small-bar">
                        <ChartistChart
                            className="small-chart flot-chart-container"
                            data={smallchart1data}
                            options={smallchart1option}
                            type={'Bar'}
                            listener={{
                              'draw': function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px'
                                  });
                                }
                              }
                            }}
                        />
                      </div>
                    </div>

                    <div className="media-body">
                      <div className="right-chart-content">
                        <h4>{stat}{' '}KM</h4><span>State</span>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                  <div className="media align-items-center">
                    <div className="hospital-small-chart">
                      <div className="small-bar">
                        <ChartistChart
                            className="small-chart2 flot-chart-container"
                            data={smallchart3data}
                            options={smallchart3option}
                            type={'Bar'}
                            listener={{
                              'draw': function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px'
                                  });
                                }
                              }
                            }}
                        />
                      </div>
                    </div>
                    <div className="media-body">
                      <div className="right-chart-content">
                        <h4>{loca}{' '}KM</h4><span>Local</span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Container>

    </Fragment>
  );
}

export default Default;