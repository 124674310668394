import axios from 'axios';
import {API_URL} from '../Constants'


class UsersService {
    GetUsersFromDatabase()
    {let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return axios.get(`${API_URL}/api/admin/getusers`,{
            withCredentials:true
            ,headers:{
                'Authorization':token
            }
        })
    }

    updateUser(user)
    {let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return axios.post(`${API_URL}/api/admin/user`,user,{
            withCredentials:true
            ,headers:{
                'Authorization':token
            }
        })
    }

    deleteUser(id)
    {let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return axios.delete(`${API_URL}/api/admin/user/${id}`,{
            withCredentials:true
            ,headers:{
                'Authorization':token
            }
        })
    }

}
export default new UsersService()
