import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from 'axios'
import { MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,UsersCountryMenu2,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete} from '../../constant'
import UsersService from "../../Axios/UsersService";
import {setLanguage} from "react-switch-lang";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {toast} from "react-toastify";
import 'reactjs-popup/dist/index.css';
import Popup from 'reactjs-popup';

const UserEdit = (props) => {


  const [data,setData] = useState([])
  const [users,setUsers]=useState([])
    const[hasusers,sethasusers]=useState(false)
  const [showUserEdit,setUserEdit]=useState(false)
  const[user,setUser]=useState([])
  const[roles,setRoles]=useState([])

  const editUser1=(id)=>{

    setUserEdit(true)
    setUser(users.find((x)=>{
      return x.id === id;
    }))
    setRoles(user.map((role)=>role.name))



    // toast.success('User details has been updated successfully')

  };


  const updateUser=(event)=>{
    event.preventDefault();
    UsersService.updateUser(user).then(
        (response)=>{
          toast.success("User has been successfully updated")
          setTimeout(function(){window.location.reload()}, 2000);
        }
    ).catch(
        function (error) {
            toast.error(error)
        }
    )

  }

  const deleteUser1=(x)=>{

    UsersService.deleteUser(x.id).then(
        (response)=>{
          toast.success("User has been successfully deleted")
          setTimeout(function(){window.location.reload()}, 2000);
        }
    )
        .catch(()=>toast.error("error"))

  };


  useEffect(() => {

      UsersService.GetUsersFromDatabase().then(
          (response)=>{
              setUsers(response.data)
              sethasusers(true)

          }
      )
  },[])

   const setfirstName=(event)=>{
        user.firstName=event.target.value
   };
    const setlastName=(event)=>{
        user.lastName=event.target.value
    };
    const setuserName=(event)=>{
        user.userName=event.target.value
    };
    const setPassword=(event)=>{
        user.password=event.target.value
    };
    const setemailName=(event)=>{
        user.email=event.target.value
    };
    const setrole=(event)=>{
        let id;
        if(event.target.value==='ROLE_USER')
            id='1738987d-5847-40fa-b5e3-5b7b1b4751bb'
        if(event.target.value==='ROLE_ADMIN')
            id='a18eb891-1d70-4407-a02e-a02210d1f208'
       user.roles.map((role)=>{
           role.name=event.target.value
           role.id=id
       })
    };
    const settel=(event)=>{
        user.tel=event.target.value
    }

    const setenabled=(event)=> {
        user.isEnabled=event.target.value
    }

    return (
    <Fragment>


      <Breadcrumb parent="Users" title="Edit Profile" />
      <Container fluid={true}>
        <div className="edit-profile">

          <Row>
            {/*<Col xl="4">*/}
            {/*  /!*<Card>*!/*/}
            {/*  /!*  /!*<CardHeader>*!/*!/*/}
            {/*  /!*  /!*  <h4 className="card-title mb-0">{MyProfile}</h4>*!/*!/*/}
            {/*  /!*  /!*  <div className="card-options">*!/*!/*/}
            {/*  /!*  /!*    <a className="card-options-collapse" href="#javascript">*!/*!/*/}
            {/*  /!*  /!*      <i className="fe fe-chevron-up"></i>*!/*!/*/}
            {/*  /!*  /!*    </a>*!/*!/*/}
            {/*  /!*  /!*    <a className="card-options-remove" href="#javascript">*!/*!/*/}
            {/*  /!*  /!*      <i className="fe fe-x"></i>*!/*!/*/}
            {/*  /!*  /!*    </a>*!/*!/*/}
            {/*  /!*  /!*  </div>*!/*!/*/}
            {/*  /!*  /!*</CardHeader>*!/*!/*/}
            {/*  /!*  <CardBody>*!/*/}
            {/*  /!*    <Form>*!/*/}
            {/*  /!*      <Row className="mb-2">*!/*/}
            {/*  /!*        <div className="col-auto">*!/*/}
            {/*  /!*          <Media className="img-70 rounded-circle" alt="" src={require("../../assets/images/user/7.jpg")} />*!/*/}
            {/*  /!*        </div>*!/*/}
            {/*  /!*        <Col>*!/*/}
            {/*  /!*          <h3 className="mb-1">{MarkJecno}</h3>*!/*/}
            {/*  /!*          <p className="mb-4">{Designer}</p>*!/*/}
            {/*  /!*        </Col>*!/*/}
            {/*  /!*      </Row>*!/*/}
            {/*  /!*      <FormGroup>*!/*/}
            {/*  /!*        <h6 className="form-label">{Bio}</h6>*!/*/}
            {/*  /!*        <Input type="textarea" className="form-control" rows="5" defaultValue="On the other hand, we denounce with righteous indignation" />*!/*/}
            {/*  /!*      </FormGroup>*!/*/}
            {/*  /!*      <FormGroup>*!/*/}
            {/*  /!*        <Label className="form-label">{EmailAddress}</Label>*!/*/}
            {/*  /!*        <Input className="form-control" placeholder="your-email@domain.com" />*!/*/}
            {/*  /!*      </FormGroup>*!/*/}
            {/*  /!*      <FormGroup>*!/*/}
            {/*  /!*        <Label className="form-label">{Password}</Label>*!/*/}
            {/*  /!*        <Input className="form-control" type="password" defaultValue="password" />*!/*/}
            {/*  /!*      </FormGroup>*!/*/}
            {/*  /!*      <FormGroup>*!/*/}
            {/*  /!*        <Label className="form-label">{Website}</Label>*!/*/}
            {/*  /!*        <Input className="form-control" placeholder="http://Uplor .com" />*!/*/}
            {/*  /!*      </FormGroup>*!/*/}
            {/*  /!*      <div className="form-footer">*!/*/}
            {/*  /!*        <button className="btn btn-primary btn-block">{Save}</button>*!/*/}
            {/*  /!*      </div>*!/*/}
            {/*  /!*    </Form>*!/*/}
            {/*  /!*  </CardBody>*!/*/}
            {/*  /!*</Card>*!/*/}
            {/*</Col>*/}
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{UsersTableTitle}</h4>
                  <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                </CardHeader>
                <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                    <tr>
                      {UsersTableHeader.map((items,i) =>
                          <th key={i}>{items}</th>
                      )}
                    </tr>
                    </thead>
                    <tbody>

                    {hasusers&&users.map((user,i) =>
                        <tr key={i}>
                          <td><a className="text-inherit" href="#javascript">{user.id} </a></td>
                          <td>{user.firstName}</td>
                          <td><span className="status-icon bg-success"></span>{user.lastName}</td>
                          <td>{user.username}</td>
                          <td>{user.password}</td>
                          <td>{user.email}</td>
                          <td>{user.isEnabled.toString()}</td>
                          <td>{user.tel}</td>
                          <td>{user.roles.map((role)=>role.name)}</td>
                          <td className="text-right">
                            <Popup onOpen={()=>setUser(user)} trigger={ <Button id={i} color="primary" size="sm" > {Edit}</Button>} position="right center" modal nested>
                              <Container fluid={true}>
                                <Card style={{
                                  maxHeight: 'calc(100vh - 210px)',
                                  overflowY: 'auto'
                                }}>
                              <Col xl="8">
                                <Form className="card">
                                  <CardHeader>
                                    <h4 className="card-title mb-0">{EditProfile}</h4>
                                    <div className="card-options">
                                      <a className="card-options-collapse" href="#javascript">
                                        <i className="fe fe-chevron-up"></i>
                                      </a>
                                      <a className="card-options-remove" href="#javascript">
                                        <i className="fe fe-x"></i>
                                      </a>
                                    </div>
                                  </CardHeader>
                                  <CardBody>
                                    <Row>
                                      <Col >
                                        <FormGroup>
                                          <Label className="form-label">Id</Label>
                                          <Input className="form-control" type="text" defaultValue={user.id} />
                                        </FormGroup>


                                        <FormGroup>
                                          <Label className="form-label">First Name</Label>
                                          <Input className="form-control" type="text" defaultValue={user.firstName} onChange={setfirstName} />
                                        </FormGroup>


                                        <FormGroup>
                                          <Label className="form-label">Last Name</Label>
                                          <Input className="form-control" type="email" defaultValue={user.lastName} onChange={setlastName} />
                                        </FormGroup>


                                        <FormGroup>
                                          <Label className="form-label">Username </Label>
                                          <Input className="form-control" type="text" defaultValue={user.username} onChange={setuserName} />
                                        </FormGroup>


                                        <FormGroup>
                                          <Label className="form-label">Password</Label>
                                          <Input className="form-control" type="text" placeholder="New Password" onChange={setPassword} />
                                        </FormGroup>


                                        <FormGroup>
                                          <Label className="form-label">Email</Label>
                                          <Input className="form-control" type="text" defaultValue={user.email} onChange={setemailName} />
                                        </FormGroup>


                                        <FormGroup>
                                          <Label className="form-label">Tel</Label>
                                          <Input className="form-control" type="text" defaultValue={user.tel} onChange={settel} />
                                        </FormGroup>


                                        {/*<FormGroup>*/}
                                        {/*  <Label className="form-label">Roles</Label>*/}
                                        {/*  <Input className="form-control" type="number" placeholder={roles} />*/}
                                        {/*</FormGroup>*/}

                                          <FormGroup>
                                              <Label className="form-label">Is Enabled</Label>
                                              <Input type="select" name="select" className="form-control btn-square" onChange={setenabled}>
                                                  {UsersCountryMenu2.map((items,i) =>
                                                      <option key={i}>{items.toString()}</option>
                                                  )}
                                              </Input>
                                          </FormGroup>

                                        <FormGroup>
                                          <Label className="form-label">Role</Label>
                                          <Input type="select" name="select" className="form-control btn-square" onChange={setrole}>
                                            {UsersCountryMenu.map((items,i) =>
                                                <option key={i}>{items}</option>
                                            )}
                                          </Input>
                                        </FormGroup>

                                        {/*<div className="form-group mb-0">*/}
                                        {/*  <Label className="form-label">About</Label>*/}
                                        {/*  <Input type="textarea" className="form-control" rows="5" placeholder="Enter About your description" />*/}
                                        {/*</div>*/}

                                      </Col>
                                    </Row>
                                  </CardBody>
                                  <CardFooter className="text-right">
                                    <button className="btn btn-primary" type="submit" onClick={updateUser}>{UpdateProfile}</button>
                                  </CardFooter>
                                </Form>
                              </Col>
                                </Card>
                              </Container>
                            </Popup>

                            {/*<Button color="transparent" size="sm" onClick={()=>updateUser1}><i className="fa fa-link"></i> {Update}</Button>*/}
                            <Button color="danger" size="sm" onClick={()=>deleteUser1(user)}><i className="fa fa-trash"></i> {Delete}</Button>
                          </td>
                        </tr>
                    )}

                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>


          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default UserEdit;
