import axios from 'axios';
import {API_URL} from '../Constants'


class GalleryPictureService {

    uploadimagetominio(image)
    {return axios.post(`${API_URL}/api/admin/files/upload`, image, {
        headers: {
            "Content-type": "application/json",
            'Content-Type': 'multipart/form-data'
        }
    })}

    addGalleryPicture(gPicture)
    {
        let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return axios.post(`${API_URL}/api/admin/gallery/upload`, gPicture,{
            headers:{
                'Authorization':token
            },
            withCredentials:true
        })
    }

    getAllNews()
    {
        return axios.get(`${API_URL}/api/news/`)
    }
    updateNews(news)
    {
        return axios.post(`${API_URL}/api/news/edit`, news)
    }

    deleteNews(newsid)
    {
        return axios.delete(`${API_URL}/api/news/${newsid}`)
    }
}
export default new GalleryPictureService()
